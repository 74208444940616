import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import { Text } from "../../locales";
import styles from "./style.module.scss";

import { ENUM_SHOWSIGNIN } from "../../reducers";

const wStyles = theme => ({
    message: {
        ...theme.typography.button,
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2
    },
    heading: {
        paddingTop: theme.spacing.unit,
        // paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit
        // paddingLeft: theme.spacing.unit * 2
    }
});

class UserInfoFragment extends React.Component {
    render() {
        const user = this.props.user || {};
        return user.userIdentifier ? (
            <div className={styles.containerOuter}>
                <div className={styles.containerInner}>
                    <div className={styles.avatarOuter}>
                        <div className={styles.avatarInner}>
                            <Avatar
                                alt="User Avatar"
                                className={styles.avatar}
                                src="https://material.io/tools/icons/static/icons/baseline-account_circle-24px.svg"
                            />
                        </div>
                    </div>
                    <div className={styles.greetings}>
                        <div className={this.props.classes.heading}>
                            <Typography variant="h5">
                                <Text id="userInfoFragment.welcome.title" />
                            </Typography>
                        </div>
                        <div className={classnames(this.props.classes.message, styles.message)}>
                            {user.userIdentifier ? (
                                user.userIdentifier
                            ) : (
                                <Text className={styles.messageInner} id="userInfoFragment.welcome.message" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.containerOuter}>
                <div className={styles.containerInner}>
                    {/* <div className={styles.avatarOuter}>
                        <div className={styles.avatarInner}>
                            <Avatar
                                alt="User Avatar"
                                className={styles.avatar}
                                src="https://material.io/tools/icons/static/icons/baseline-account_circle-24px.svg"
                            />
                        </div>
                    </div> */}
                    <div className={styles.greetings}>
                        <div className={this.props.classes.heading}>
                            <Typography variant="h5">
                                <Text id="userInfoFragment.anonymous.title" />
                            </Typography>
                        </div>
                        <div>
                            <Button color="primary" variant="outlined" size="small" onClick={this.handlerSignInOnClick}>
                                <Text id="userInfoFragment.anonymous.message" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handlerSignInOnClick = () => {
        const { dispatch } = this.props;
        dispatch({
            type: ENUM_SHOWSIGNIN.SHOW
        });
    };
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(withStyles(wStyles)(UserInfoFragment));
