import { DIALOG } from "../reducers/index.js";

export function showAlert(title, body) {
    return {
        type: DIALOG.SHOW,
        payload: {
            title,
            body,
            primaryButton: {
                text: "OK"
            }
        }
    };
}
