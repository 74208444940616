import React from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";
import linkState from "linkstate";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { get } from "lodash-es";

import {
    SERVICELOGIN_LOGIN,
    SERVICELOGIN_SIGNUP,
    SERVICELOGIN_SIGNUP_VALIDATE,
    SERVICELOGIN_RESET,
    SERVICELOGIN_RESET_VALIDATE
} from "../../configs/ApiUrls";
import { enqueueSnackbar } from "../../actions/snackbar";

import { postJson } from "../../utils/apiClient";

import { t } from "../../locales";

import styles from "./style.module.scss";

import { ENUM_SHOWSIGNIN, ENUM_USERINFO_LOAD } from "../../reducers";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const regexPhoneOrEmail = /(^\+?\d{8,}$)|(^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$)/gi;

const initState = {
    variant: "signin", // signin, signup, resetpw
    showRegister: false,
    revealPassword: false,
    otpStatus: "ready", // ready/pending/disabled
    forms: {
        account: "",
        otp: "",
        password: ""
    },
    signup: {
        otpNonce: null, // nonce for this otp
        otpExpire: 0, // otp expire time, in epoch second
        otpPrefix: null
    }
};

class signInFragment extends React.Component {
    state = initState;

    handleSignInClose = () => {
        let { dispatch } = this.props;
        dispatch({
            type: ENUM_SHOWSIGNIN.HIDE
        });
    };

    handleToggleSignin = e => {
        e.preventDefault();
        this.setState({ showRegister: !this.state.showRegister });
    };

    handleChangeVariant = variant => e => {
        e.preventDefault();
        this.setState({ variant });
    };

    handleToggleRevealPassword = () => this.setState({ revealPassword: !this.state.revealPassword });

    handleSignUpOTP = async () => {
        try {
            if (!this.state.forms.account) {
                throw new Error(t("signInFragment.messages.missingAccount"));
            }

            this.setState({
                otpStatus: "pending"
            });

            let apiRequestOTP;

            switch (this.state.variant) {
                case "signup":
                    apiRequestOTP = SERVICELOGIN_SIGNUP;
                    break;
                case "resetpw":
                    apiRequestOTP = SERVICELOGIN_RESET;
                    break;
                default:
                    break;
            }

            const resData = await postJson(apiRequestOTP, {
                userIdentifier: this.state.forms.account
            });

            const { prefix, nonce, expire } = resData;

            this.setState({
                otpStatus: "disabled",
                signup: {
                    otpPrefix: prefix,
                    otpNonce: nonce,
                    otpExpire: expire
                }
            });

            setTimeout(() => this.setState({ otpStatus: "ready" }), 30000);
            this.props.dispatch(
                enqueueSnackbar({
                    message: "OTP sent!",
                    options: {
                        variant: "success"
                    }
                })
            );
            console.log("STUB: OTP Sent");
        } catch (err) {
            this.setState({
                otpStatus: "ready"
            });
            this.props.dispatch(
                enqueueSnackbar({
                    message: err.message,
                    options: {
                        variant: "warning"
                    }
                })
            );
        }
    };

    handleSignUpValidate = async e => {
        e.preventDefault();
        try {
            const { otp, password } = this.state.forms;
            const { otpNonce } = this.state.signup;
            if (!otpNonce) {
                throw new Error(t("signInFragment.messages.missingOTPRequest"));
            }
            if (!password || password.length < 8) {
                throw new Error(t("signInFragment.messages.badPassword"));
            }
            if (!otp) {
                throw new Error(t("signInFragment.messages.missingOTP"));
            }
            let apiValidateOTP;

            switch (this.state.variant) {
                case "signup":
                    apiValidateOTP = SERVICELOGIN_SIGNUP_VALIDATE;
                    break;
                case "resetpw":
                    apiValidateOTP = SERVICELOGIN_RESET_VALIDATE;
                    break;
                default:
                    break;
            }

            const resData = await postJson(apiValidateOTP, {
                nonce: otpNonce,
                otp: otp,
                password: password,
                utm_source: get(window, "globalStorage.utmSource")
            });

            console.log(resData);
            this.setState(initState);
            this.props.dispatch({
                type: ENUM_SHOWSIGNIN.HIDE
            });
            this.props.dispatch(
                enqueueSnackbar({
                    message:
                        this.state.variant === "signup"
                            ? t("signInFragment.messages.signupSuccess")
                            : t("signInFragment.messages.resetPasswordSuccess"),
                    options: {
                        variant: "success"
                    }
                })
            );
            this.props.dispatch({
                type: ENUM_USERINFO_LOAD.SUCCESS,
                payload: resData
            });
        } catch (err) {
            this.props.dispatch(
                enqueueSnackbar({
                    message: err.message,
                    options: {
                        variant: "warning"
                    }
                })
            );
        }
    };

    handleSignIn = async e => {
        try {
            e.preventDefault();
            const { password, account } = this.state.forms;
            if (!account) {
                throw new Error(t("signInFragment.messages.missingAccount"));
            }
            if (!password || password.length < 8) {
                throw new Error(t("signInFragment.messages.badPassword"));
            }
            const resData = await postJson(SERVICELOGIN_LOGIN, {
                password: this.state.forms.password,
                userIdentifier: this.state.forms.account
            });
            console.log(resData);
            this.setState(initState);
            this.props.dispatch({
                type: ENUM_SHOWSIGNIN.HIDE
            });
            this.props.dispatch(
                enqueueSnackbar({
                    message: t("signInFragment.messages.loginSuccess"),
                    options: {
                        variant: "success"
                    }
                })
            );
            this.props.dispatch({
                type: ENUM_USERINFO_LOAD.SUCCESS,
                payload: resData
            });
        } catch (err) {
            this.props.dispatch(
                enqueueSnackbar({
                    message: err.message,
                    options: {
                        variant: "warning"
                    }
                })
            );
        }
    };

    render() {
        const { forms } = this.state;
        const otpPrefix = this.state.signup.otpPrefix;
        const showOTP = ["signup", "resetpw"].includes(this.state.variant);
        let otpInputEndAdornment;
        switch (this.state.otpStatus) {
            case "ready":
            case "disabled":
                otpInputEndAdornment = (
                    <IconButton
                        aria-label="Send OTP"
                        disabled={this.state.otpStatus === "disabled"}
                        onClick={this.handleSignUpOTP}
                    >
                        <SendIcon />
                    </IconButton>
                );
                break;
            case "pending":
                otpInputEndAdornment = (
                    <CircularProgress variant="indeterminate" size={48} className={styles.adornmentSpinner} />
                );
                break;
            default:
                otpInputEndAdornment = null;
                break;
        }
        return (
            <Dialog
                fullScreen
                open={this.props.signIn.visible}
                onClose={this.handleSignInClose}
                TransitionComponent={Transition}
            >
                <AppBar position="relative">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleSignInClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                            {
                                {
                                    resetpw: t("signInFragment.options.resetPassword"),
                                    signup: t("signInFragment.options.signUp"),
                                    signin: t("signInFragment.options.signIn")
                                }[this.state.variant]
                            }
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={styles.mainOuter} onSubmit={this.handleSignUpValidate}>
                    <Grid container spacing={24}>
                        <Grid item>
                            <Paper elevation={2}>
                                <div className={styles.mainInner}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <div className={styles.item}>
                                                <TextField
                                                    label={t("signInFragment.labels.account")}
                                                    type="text"
                                                    name="account"
                                                    autoComplete="off"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={forms.account}
                                                    onInput={linkState(this, "forms.account")}
                                                    inputProps={{
                                                        pattern: regexPhoneOrEmail.toString(),
                                                        required: true
                                                    }}
                                                    fullWidth
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={styles.item}>
                                                <TextField
                                                    label={
                                                        this.state.variant === "resetpw"
                                                            ? t("signInFragment.labels.newPassword")
                                                            : t("signInFragment.labels.password")
                                                    }
                                                    type={this.state.revealPassword ? "text" : "password"}
                                                    name="password"
                                                    autoComplete="off"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={forms.password}
                                                    onInput={linkState(this, "forms.password")}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="Toggle password visibility"
                                                                    onClick={this.handleToggleRevealPassword}
                                                                >
                                                                    {this.state.revealPassword ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                />
                                            </div>
                                        </Grid>
                                        {showOTP && (
                                            <Grid item xs={12}>
                                                <div className={styles.item}>
                                                    <TextField
                                                        name="otp"
                                                        label={t("signInFragment.labels.otp")}
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        autoComplete="off"
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={forms.otp}
                                                        onInput={linkState(this, "forms.otp")}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: otpPrefix ? true : undefined
                                                        }}
                                                        InputProps={{
                                                            startAdornment: otpPrefix && (
                                                                <InputAdornment position="start">
                                                                    {otpPrefix}-
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {otpInputEndAdornment}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        helperText={
                                                            otpPrefix
                                                                ? t("signInFragment.messages.otpCheckSMS")
                                                                : t("signInFragment.messages.otpTapForOTP")
                                                        }
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <div className={styles.item}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    type="submit"
                                                    onClick={showOTP ? this.handleSignUpValidate : this.handleSignIn}
                                                >
                                                    {
                                                        {
                                                            resetpw: t("signInFragment.options.resetPassword"),
                                                            signup: t("signInFragment.options.signUp"),
                                                            signin: t("signInFragment.options.signIn")
                                                        }[this.state.variant]
                                                    }
                                                </Button>
                                            </div>
                                        </Grid>
                                        {this.state.variant !== "signup" && (
                                            <Grid item xs={6} sm={6}>
                                                <Typography variant="caption">
                                                    {t("signInFragment.messages.noAccount")}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    component="a"
                                                    href="#"
                                                    onClick={this.handleChangeVariant("signup")}
                                                >
                                                    {t("signInFragment.messages.plsSignUp")}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {this.state.variant !== "signin" && (
                                            <Grid item xs={6} sm={6}>
                                                <Typography variant="caption">
                                                    {t("signInFragment.messages.haveAccount")}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    component="a"
                                                    href="#"
                                                    onClick={this.handleChangeVariant("signin")}
                                                >
                                                    {t("signInFragment.messages.plsSignIn")}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {this.state.variant !== "resetpw" && (
                                            <Grid item xs={6} sm={6}>
                                                <Typography variant="caption">
                                                    {t("signInFragment.messages.forgotPassword")}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    component="a"
                                                    href="#"
                                                    onClick={this.handleChangeVariant("resetpw")}
                                                >
                                                    {t("signInFragment.messages.plsResetPassword")}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    signIn: state.signIn
});

export default connect(mapStateToProps)(signInFragment);
