import url from "url";

const API_BASE = process.env.REACT_APP_API_BASE;

export const API_JSAPI = url.resolve(API_BASE, "api/jsapi");
export const API_OPENID = url.resolve(API_BASE, "api/openid");
export const API_COUPONS = url.resolve(API_BASE, "api/coupons");
export const API_COUPON_ADD = url.resolve(API_BASE, "api/coupon/add");
export const API_COUPON_SAVE = url.resolve(API_BASE, "api/coupon/save");
export const API_COUPON_VIEW = url.resolve(API_BASE, "api/coupon/view");
export const API_COUPON_OPEN = url.resolve(API_BASE, "api/coupon/open");
export const API_STATS = url.resolve(API_BASE, "api/stats/view");
export const API_PRODUCT_CATEGORIES = url.resolve(API_BASE, "api/category");
export const API_PRODUCT_BY_CATEGORY = url.resolve(API_BASE, "api/category/");
export const API_PRODUCT_BY_ID = url.resolve(API_BASE, "api/product/");
export const SERVICELOGIN_LOGIN = url.resolve(API_BASE, "servicelogin/login");
export const SERVICELOGIN_SIGNUP = url.resolve(API_BASE, "servicelogin/signup");
export const SERVICELOGIN_SIGNUP_RESEND = url.resolve(API_BASE, "servicelogin/signup/resend");
export const SERVICELOGIN_SIGNUP_VALIDATE = url.resolve(API_BASE, "servicelogin/signup/validate");
export const SERVICELOGIN_RESET = url.resolve(API_BASE, "servicelogin/reset");
export const SERVICELOGIN_RESET_VALIDATE = url.resolve(API_BASE, "servicelogin/reset/validate");
export const AUTHAPI_USER_INFO = url.resolve(API_BASE, "authapi/user/info");
export const AUTHAPI_CHECKOUT = url.resolve(API_BASE, "authapi/checkout");
export const AUTHAPI_LOGOUT = url.resolve(API_BASE, "authapi/logout");
export const AUTHAPI_ORDER_HISTORY = url.resolve(API_BASE, "authapi/order/history");
export const AUTHAPI_ORDER_QUERY_BY_ID = url.resolve(API_BASE, "authapi/order/");
export const PGW_PAYMENT = process.env.REACT_APP_PGW_API_BASE;
