import React, { Component } from "react";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InboxIcon from "@material-ui/icons/Inbox";
import PaymentIcon from "@material-ui/icons/Payment";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { Typography } from "@material-ui/core";
import styles from "./style.module.scss";
import { ENUM_CART, ENUM_SHOWSIGNIN } from "../../reducers";
import { AUTHAPI_CHECKOUT, PGW_PAYMENT } from "../../configs/ApiUrls";
import { postJson } from "../../utils/apiClient";
import { Text } from "../../locales";

const actionCartCheckOut = async (dispatch, getState) => {
    const state = getState();
    const { cart, user } = state;

    // check login
    if (!user.userIdentifier) {
        dispatch({
            type: ENUM_SHOWSIGNIN.SHOW
        });
        throw new Error("Please login before checking out.");
    }

    const resData = await postJson(AUTHAPI_CHECKOUT, {
        returnUrl: new URL("/history", window.location.origin).toString(),
        order: cart
    });
    console.log(resData);

    let hWebPaymentWindow = window;
    // Create a form
    let paymentForm = hWebPaymentWindow.document.createElement("form");
    paymentForm.method = "POST";
    paymentForm.action = PGW_PAYMENT;
    paymentForm.hidden = true;
    Object.entries(resData).forEach(([key, val]) => {
        // Create an input
        let valInput = document.createElement("input");
        valInput.type = "text";
        valInput.name = key;
        valInput.value = val;
        paymentForm.appendChild(valInput);
    });
    dispatch({
        type: ENUM_CART.CLEAR
    });
    hWebPaymentWindow.document.body.appendChild(document.createTextNode("Redirecting..."));
    hWebPaymentWindow.document.body.appendChild(paymentForm);
    paymentForm.submit();
};

class CartPage extends Component {
    state = {
        loading: false
    };

    handleButtonClearCartClicked = () => {
        this.props.dispatch({
            type: ENUM_CART.CLEAR
        });
    };

    handleCheckoutButtonClicked = () => {
        this.props.dispatch(actionCartCheckOut);
    };

    render() {
        const { cart } = this.props;
        const totalAmount = cart.reduce((prev, curr) => prev + parseFloat(curr.price, "10") * curr.quantity, 0);
        return (
            <div style={{ backgroundColor: "white" }}>
                <List
                    subheader={
                        <ListSubheader component="div">
                            <Text id="cart.labels.cart" />
                        </ListSubheader>
                    }
                >
                    {cart.length > 0 ? (
                        cart.map((cartItem, idx) => (
                            <ListItem key={idx}>
                                <Avatar>
                                    <InboxIcon />
                                </Avatar>
                                <ListItemText primary={cartItem.name} secondary={JSON.stringify(cartItem.options)} />
                                <ListItemSecondaryAction className={styles.listItemSecondaryAction}>
                                    <Typography variant="subtitle1">{`$${parseFloat(cartItem.price, 10).toFixed(2)} x ${
                                        cartItem.quantity
                                    }`}</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText
                                primary={<Text id="cart.messages.cartEmpty" />}
                                secondary={<Text id="cart.messages.addSomeItems" />}
                            />
                        </ListItem>
                    )}
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemText primary={<Text id="cart.labels.total" />} />
                        <ListItemSecondaryAction className={styles.listItemSecondaryAction}>
                            <Typography variant="h5">${totalAmount.toFixed(2)}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={styles.listItemCheckout}>
                        <IconButton
                            aria-label="Clear cart"
                            onClick={this.handleButtonClearCartClicked}
                            disabled={cart.length === 0}
                        >
                            <RemoveShoppingCartIcon />
                        </IconButton>
                        <ListItemSecondaryAction className={styles.listItemSecondaryAction}>
                            <Button
                                variant="contained"
                                onClick={this.handleCheckoutButtonClicked}
                                color="primary"
                                disabled={cart.length === 0}
                            >
                                <Text id="cart.labels.checkout" />
                                &nbsp;
                                <PaymentIcon />
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </div>
        );
    }

    handlerSignInOnClick = () => {
        alert("Sign in");
    };
}

const mapStateToProps = state => ({ cart: state.cart });

export default connect(mapStateToProps)(CartPage);
