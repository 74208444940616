import React from "react";
import classnames from "classnames";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import styles from "./style.module.scss";
import { withStyles } from "@material-ui/core";

const wStyles = theme => ({
    expand: {
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        marginLeft: "auto"
    },
    expandOpen: {
        transform: "rotate(90deg)"
    }
});

class ProductItemFragment extends React.Component {
    state = {
        expanded: false
    };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleItemClick = id => () => {
        let { dispatch } = this.props;
        dispatch(push("/products/" + id));
    };

    render() {
        const subItems = this.props.subItems;

        const title = this.props.title;
        const description = this.props.description;
        const logoImg = this.props.logo;
        switch (subItems.length) {
            case 0:
                return <div>...</div>;
            case 1:
                return (
                    <Card
                        className={styles.card}
                        onClick={() => this.props.dispatch(push(`/products/${subItems[0].key}`))}
                    >
                        <CardMedia className={styles.cover} image={logoImg} title="Product image" />
                        <div className={styles.detailsOuter}>
                            <CardContent className={styles.detailsInner}>
                                <Typography component="h6" variant="h6" className={styles.title}>
                                    {title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className={styles.description}
                                    // -webkit-box-orient missing from SCSS
                                    style={{ WebkitBoxOrient: "vertical" }}
                                >
                                    {description}
                                </Typography>
                            </CardContent>
                        </div>
                        <div className={styles.actionOuter}>
                            <IconButton aria-label="Purchase">
                                <ChevronRight className={styles.actionButton} />
                            </IconButton>
                        </div>
                    </Card>
                );
            default:
                return (
                    <Card>
                        <Paper className={classnames(styles.card, styles.cardNoRadius)} elevation={1}>
                            <CardMedia
                                className={styles.cover}
                                image="https://material-ui.com/static/images/cards/live-from-space.jpg"
                                title="Product image"
                            />
                            <div className={styles.detailsOuter}>
                                <CardContent className={styles.detailsInner}>
                                    <Typography component="h6" variant="h6" className={styles.title}>
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        className={styles.description}
                                        // -webkit-box-orient missing from SCSS
                                        style={{ WebkitBoxOrient: "vertical" }}
                                    >
                                        {description}
                                    </Typography>
                                </CardContent>
                            </div>
                            <div className={styles.actionOuter}>
                                <div className={styles.actionInner}>
                                    <IconButton
                                        className={classnames(this.props.classes.expand, {
                                            [this.props.classes.expandOpen]: this.state.expanded
                                        })}
                                        onClick={this.handleExpandClick}
                                        aria-expanded={this.state.expanded}
                                        aria-label="Show more"
                                    >
                                        <ChevronRight className={styles.actionButton} />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                        <Collapse in={this.state.expanded} timeout="auto" className={styles.collapse} unmountOnExit>
                            <CardContent>
                                {subItems.map(item => (
                                    <Chip
                                        key={item.key}
                                        className={styles.chip}
                                        label={item.name}
                                        component="a"
                                        clickable
                                        onClick={this.handleItemClick(item.key)}
                                    />
                                ))}
                            </CardContent>
                        </Collapse>
                    </Card>
                );
        }
    }
}

export default connect()(withStyles(wStyles)(ProductItemFragment));
