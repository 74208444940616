import React from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { ENUM_CART } from "../../../reducers";
import linkState from "linkstate";
import { enqueueSnackbar } from "../../../actions/snackbar";
import { Text } from "../../../locales";
import { push } from "connected-react-router";

const wStyles = theme => ({
    container: {
        padding: theme.spacing.unit
    },
    root: {
        padding: theme.spacing.unit
    },
    chip: {
        marginRight: theme.spacing.unit
    },
    section1: {
        margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`
    },
    section2: {
        margin: theme.spacing.unit * 2
    },
    section3: {
        margin: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`
    }
});

class CommonProductPage extends React.Component {
    state = {
        selectedSkuIdx: 0,
        forms: {}
    };

    handleAddToCartClicked = e => {
        e.preventDefault();
        try {
            const { product, dispatch } = this.props;
            for (const elem of product.inputs) {
                if (!this.state.forms[elem.name]) throw new Error(`${elem.label} is required`);
            }
            const selectedSKU = product.sku[this.state.selectedSkuIdx];
            dispatch({
                type: ENUM_CART.ADD,
                payload: {
                    sku: selectedSKU.sku,
                    name: `${product.title}-${selectedSKU.description}`,
                    price: selectedSKU.unitPrice,
                    ...this.state.forms,
                    quantity: parseInt(this.state.forms.quantity) || 1
                }
            });
            dispatch(push("/cart"));
            dispatch(
                enqueueSnackbar({
                    message: <Text id="cart.messages.itemAdded" />,
                    options: {
                        variant: "success"
                    }
                })
            );
        } catch (err) {
            this.props.dispatch(
                enqueueSnackbar({
                    message: err.message,
                    options: {
                        variant: "warning"
                    }
                })
            );
        }
    };

    handleProductSKUChipClicked = skuIdx => e => {
        this.setState({
            selectedSkuIdx: skuIdx
        });
    };

    render() {
        const { product } = this.props;
        const skuList = product.sku;
        const selectedSKU = skuList[this.state.selectedSkuIdx];
        const quantity = parseInt(this.state.forms.quantity || 1);
        const { unitPrice } = selectedSKU;
        return (
            <div className={this.props.classes.container}>
                <Paper elevation={2}>
                    <form className={this.props.classes.root} onSubmit={this.handleAddToCartClicked}>
                        <div className={this.props.classes.section1}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography gutterBottom variant="h4">
                                        {product.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <h2>
                                <Text id="productDetail.productDetail" />
                            </h2>
                            <pre>{JSON.stringify(this.props.match, null, 4)}</pre>
                            <Typography color="textSecondary">{product.fullDescription}</Typography>
                        </div>
                        {product.inputs.length > 0 && (
                            <React.Fragment>
                                <Divider variant="middle" />
                                <div className={this.props.classes.section1}>
                                    <Grid container alignItems="center">
                                        <Typography gutterBottom variant="body1">
                                            <Text id="productDetail.purchasingOptions" />
                                        </Typography>
                                        {product.inputs.map(elem => (
                                            <TextField
                                                key={elem.name}
                                                label={elem.label}
                                                fullWidth
                                                type={elem.type}
                                                margin="normal"
                                                variant="outlined"
                                                value={this.state.forms[elem.name] || ""}
                                                helperText={elem.helperText}
                                                required
                                                inputProps={{
                                                    min: elem.min,
                                                    max: elem.max
                                                }}
                                                onChange={linkState(this, `forms.${elem.name}`)}
                                            />
                                        ))}
                                    </Grid>
                                </div>
                            </React.Fragment>
                        )}
                        <Divider variant="middle" />
                        <div className={this.props.classes.section2}>
                            <Typography gutterBottom variant="body1">
                                <Text id="productDetail.selectVariant" />
                            </Typography>
                            <div>
                                {skuList.map((elem, idx) => (
                                    <Chip
                                        className={this.props.classes.chip}
                                        label={elem.description}
                                        onClick={this.handleProductSKUChipClicked(idx)}
                                        color={idx === this.state.selectedSkuIdx ? "secondary" : "default"}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={this.props.classes.section2}>
                            <Grid item>
                                <Typography gutterBottom variant="body1">
                                    <Text id="productDetail.price" />
                                </Typography>
                                <Typography gutterBottom variant="h6">
                                    {quantity > 1
                                        ? `$${unitPrice} x ${quantity} = $${(unitPrice * quantity).toFixed(2)}`
                                        : `$${unitPrice}`}
                                </Typography>
                            </Grid>
                        </div>
                        <div className={this.props.classes.section3}>
                            <Button variant="contained" color="primary" fullWidth type="submit">
                                <Text id="productDetail.addToCart" />
                            </Button>
                        </div>
                    </form>
                </Paper>
            </div>
        );
    }
}

export default withStyles(wStyles)(connect()(CommonProductPage));
