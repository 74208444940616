import React, { Component } from "react";
import styles from "./App.module.scss";
import url from "url";
import { Route, Switch } from "react-router-dom";
import reactListKey from "./utils/reactListKey";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { Text } from "./locales";

import Paper from "@material-ui/core/Paper";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Badge from "@material-ui/core/Badge";

import StoreIcon from "@material-ui/icons/Store";
import HistoryIcon from "@material-ui/icons/History";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import ProductPage from "./routes/product";
import CartPage from "./routes/cart";
import HistoryPage from "./routes/history";
import MePage from "./routes/me";
import SignInFragment from "./components/SignInFragment";
import Notifier from "./components/Notifier";
import Dialog from "./components/Dialog";
import themeDefinition from "./theme.js";
import { getJson } from "./utils/apiClient";
import { AUTHAPI_USER_INFO } from "./configs/ApiUrls";
import { ENUM_USERINFO_LOAD } from "./reducers";
import { withStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga";

const theme = createMuiTheme(themeDefinition);

const jss = {
    snackBarRoot: {
        marginBottom: "48px"
    }
};

const withBadge = ({ content }) => wrapped => (
    <Badge badgeContent={content} color="secondary">
        {wrapped}
    </Badge>
);

const actionCheckLogin = async (dispatch, getState) => {
    try {
        const resData = await getJson(AUTHAPI_USER_INFO);
        console.log(resData);
        if (resData) {
            dispatch({
                type: ENUM_USERINFO_LOAD.SUCCESS,
                payload: resData
            });
        }
    } catch (err) {
        console.warn(err);
    }
};

class App extends Component {
    views = [
        {
            path: "/products",
            label: <Text id="app.tabs.products" />,
            icon: <StoreIcon />,
            render: <ProductPage />
        },
        {
            path: "/cart",
            label: <Text id="app.tabs.cart" />,
            icon: <ShoppingCartIcon />,
            render: <CartPage />
        },
        {
            path: "/history",
            label: <Text id="app.tabs.history" />,
            icon: <HistoryIcon />,
            render: <HistoryPage />
        },
        {
            path: "/me",
            label: <Text id="app.tabs.me" />,
            icon: <AccountCircleIcon />,
            render: <MePage />
        }
    ];

    getPathAtTab(tab) {
        let { views } = this;
        if (tab < 0 || tab >= views.length) {
            throw new Error(`Invalid tab index ${tab}`);
        }
        const { path } = views[tab];
        return path;
    }

    handleOnClick = tab => () => {
        let { dispatch } = this.props;
        const hrefParsed = url.parse(window.location.href);
        const path = this.getPathAtTab(tab);
        if (hrefParsed.pathname === path) {
            return;
        }
        this.setState({
            tab: tab
        });
        dispatch(push(path));
    };

    actionTabInit = () => {
        let { views } = this;
        const hrefParsed = url.parse(window.location.href);
        for (let tab = 0; tab < views.length; tab++) {
            const { path } = views[tab];
            if (hrefParsed.pathname === path) {
                this.setState({
                    tab: tab
                });
                return;
            }
        }
    };

    onRouteChanged(prevLocation) {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    componentDidMount() {
        this.props.dispatch(actionCheckLogin);
    }

    render() {
        const { router, cart } = this.props;
        const { views } = this;
        const pathname = router.location.pathname;
        let bottomNavTabId = 0;
        const cartBadge = cart.length > 0 ? cart.length : null;

        for (let i = 0; i < views.length; i++) {
            if (pathname.startsWith(views[i].path)) {
                // TODO: match overlapping prefixes correctly
                // e.g: /apple vs /applepie vs /apple/jam
                bottomNavTabId = i;
                break;
            }
        }

        return (
            <SnackbarProvider
                classes={{
                    root: this.props.classes.snackBarRoot
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                maxSnack={2}
            >
                <MuiThemeProvider theme={theme}>
                    <Paper elevation={4} className={styles.App}>
                        <main>
                            <Switch>
                                {views.map((v, i) => (
                                    <Route
                                        key={reactListKey("route", i.toFixed(0))}
                                        path={v.path}
                                        render={props => v.render}
                                    />
                                ))}
                                <Route render={() => <Redirect to="/products" />} />
                            </Switch>
                        </main>
                        <Paper component="nav" elevation={4} className={styles.navigationOuter}>
                            <BottomNavigation
                                value={bottomNavTabId}
                                onChange={this.handleChange}
                                showLabels
                                className={styles.navigationInner}
                            >
                                {views.map((v, i) => (
                                    <BottomNavigationAction
                                        classes={{
                                            selected: styles.navigationLabel
                                        }}
                                        key={reactListKey("bottom-navigation", i.toFixed(0))}
                                        label={v.label}
                                        icon={
                                            v.path === "/cart" && cartBadge
                                                ? withBadge({ content: cartBadge })(v.icon)
                                                : v.icon
                                        }
                                        onClick={this.handleOnClick(i)}
                                    />
                                ))}
                            </BottomNavigation>
                        </Paper>
                    </Paper>
                    <SignInFragment />
                    <Notifier />
                    <Dialog />
                </MuiThemeProvider>
            </SnackbarProvider>
        );
    }
}

const mapStateToProps = state => ({
    router: state.router,
    cart: state.cart
});

export default withRouter(connect(mapStateToProps)(withStyles(jss)(App)));
