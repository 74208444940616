import React from "react";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import { loadProductByCategory } from "../../actions/products";
import styles from "./style.module.scss";
import { get } from "lodash-es";
import { Text } from "../../locales";
import ProductItemFragment from "../ProductItemFragment";
import { Typography } from "@material-ui/core";

class ProductListFragment extends React.Component {
    state = {};

    loadCurrCategory = () => {
        console.log(this.props.categoryName);
        this.props.dispatch(loadProductByCategory(this.props.category));
    };

    componentDidMount() {
        //TODO: correctly trigger loading
        this.loadCurrCategory();
    }

    render() {
        const productsList = get(this.props, "productByCategory.payload." + this.props.category.id);
        return (
            <List component="nav">
                {Array.isArray(productsList) ? (
                    productsList.map(item => (
                        <div className={styles.item} key={item.id}>
                            <ProductItemFragment
                                key={item.id}
                                title={item.title}
                                logo={item.logo}
                                description={item.description}
                                subItems={[{ key: item.id, name: "*" }]}
                            />
                        </div>
                    ))
                ) : (
                    <Typography variant="body2">
                        <Text id="common.loading" />
                    </Typography>
                )}
            </List>
        );
    }
}

const mapStateToProps = state => ({
    productByCategory: state.productByCategory
});

export default connect(mapStateToProps)(ProductListFragment);
