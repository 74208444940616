import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { DIALOG } from "../reducers";
import { Text } from "../locales";

class CommonDialog extends React.Component {
    handleClose = actionHandler => () => {
        actionHandler && actionHandler();
        this.props.dispatch({
            type: DIALOG.HIDE
        });
    };

    render() {
        const { dialog } = this.props;
        return (
            <Dialog
                open={dialog.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialog.title || <Text id="dialog.labels.attention" />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{dialog.body}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {dialog.secondaryButton && (
                        <Button onClick={this.handleClose(dialog.secondaryButton.action)} color="primary">
                            {dialog.secondaryButton.text}
                        </Button>
                    )}
                    {dialog.primaryButton && (
                        <Button onClick={this.handleClose(dialog.primaryButton.action)} color="primary" autoFocus>
                            {dialog.primaryButton.text}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStoreToProps = store => ({
    dialog: store.dialog
});

export default connect(mapStoreToProps)(CommonDialog);
