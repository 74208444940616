import React from "react";
import { get } from "lodash-es";
import template from "preact-i18n/src/lib/template";

const IntlContext = React.createContext({});

interface IntlProviderProps {
    definition: Object;
    children: React.ReactNode;
}

export const IntlProvider: React.FunctionComponent<IntlProviderProps> = props => (
    <IntlContext.Provider value={props.definition}>{props.children}</IntlContext.Provider>
);

interface TextProps {
    id: string;
    fields?: Object;
    children?: React.ReactNode;
}

export const Text: React.FunctionComponent<TextProps> = props => (
    <IntlContext.Consumer>
        {definition => template(get(definition, props.id, props.children), props.fields)}
    </IntlContext.Consumer>
);
