export function assertAPIError(resBody) {
    if (resBody.errorCode) throw new Error(resBody.errorMessage);
    if (resBody.resultCode) throw new Error(resBody.resultMessage);
}

export function assertResultStatus(res) {
    const statusCode = res.status;
    switch (statusCode) {
        case 429:
            throw new Error("You've requested too fast, please try again in a moment.");
        default:
            break;
    }
}

export const fetchJson = async (url, init, options = { assertAPIError: true, assertResultStatus: true }) => {
    const res = await fetch(url, init);

    options.assertResultStatus && assertResultStatus(res);

    const resBody = await res.json();

    options.assertAPIError && assertAPIError(resBody);

    return resBody.data;
};

export const postJson = async (url, bodyObj, init = {}, options) => {
    let fetchInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "same-origin",
        ...init
    };

    if (typeof bodyObj === "object") {
        fetchInit.body = JSON.stringify(bodyObj);
    }

    return fetchJson(url, fetchInit, options);
};

export const getJson = async (url, init = {}, options) => {
    let fetchInit = {
        method: "GET",
        credentials: "same-origin",
        ...init
    };

    return fetchJson(url, fetchInit, options);
};

export const deleteJson = async (url, bodyObj, init = {}, options) => {
    let fetchInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "same-origin",
        ...init
    };

    if (typeof bodyObj === "object") {
        fetchInit.body = JSON.stringify(bodyObj);
    }

    return fetchJson(url, fetchInit, options);
};
