import { ENUM_LOAD_PRODUCT_BY_CATEGORY } from "../reducers";
import { API_PRODUCT_BY_CATEGORY } from "../configs/ApiUrls";

export const loadProductByCategory = category => async (dispatch, getState) => {
    const currState = getState();
    try {
        if (!category) return;
        if (currState.pending) return;
        dispatch({
            type: ENUM_LOAD_PRODUCT_BY_CATEGORY.STARTED,
            payload: null,
            error: null
        });
        let res = await fetch(API_PRODUCT_BY_CATEGORY + category.id);
        let { resultCode, errorCode, data } = await res.json();
        if (resultCode !== 0) {
            throw new Error(`Error retrieving data from server (invalid resultCode).`);
        }
        if (errorCode !== 0) {
            throw new Error(`Error retrieving data from server (invalid errorCode).`);
        }
        dispatch({
            type: ENUM_LOAD_PRODUCT_BY_CATEGORY.SUCCESS,
            payload: { [category.id]: data },
            error: null
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: ENUM_LOAD_PRODUCT_BY_CATEGORY.ERROR,
            payload: null,
            error: e
        });
    }
};
