import React, { Component } from "react";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import { getJson, fetchJson } from "../../utils/apiClient";
import { AUTHAPI_ORDER_HISTORY, AUTHAPI_ORDER_QUERY_BY_ID } from "../../configs/ApiUrls";
import { ENUM_LOAD_ORDER_HISTORY, DIALOG } from "../../reducers";
import { sleep } from "../../utils/async";
import { Text } from "../../locales";
import { enqueueSnackbar } from "../../actions/snackbar";
import styles from "./style.module.scss";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const NUMBER_EACH_FETCH = 20;

const actionFetchOrderHistory = async (dispatch, getState) => {
    const { orderHistory } = getState();
    const urlOrderHistory = new URL(AUTHAPI_ORDER_HISTORY, window.location.origin);
    urlOrderHistory.searchParams.set("offset", orderHistory.length);
    urlOrderHistory.searchParams.set("limit", NUMBER_EACH_FETCH);
    const resData = await getJson(urlOrderHistory);
    dispatch({
        type: ENUM_LOAD_ORDER_HISTORY.LOAD_MORE,
        payload: resData
    });
};

const actionUpdateOneOrder = orderId => async (dispatch, getState) => {
    const urlOrderHistory = new URL(`${AUTHAPI_ORDER_QUERY_BY_ID}${orderId}`, window.location.origin);
    const resData = await getJson(urlOrderHistory);
    dispatch({
        type: ENUM_LOAD_ORDER_HISTORY.UPDATE_ONE,
        payload: resData
    });
};

const actionRefundOneOrder = orderId => async (dispatch, getState) => {
    const urlOrderHistory = new URL(`${AUTHAPI_ORDER_QUERY_BY_ID}${orderId}`, window.location.origin);
    await fetchJson(urlOrderHistory, {
        method: "PATCH"
    });

    // show successful result if not errored in fetchJson()
    dispatch(
        enqueueSnackbar({
            message: <Text id="history.messages.refundSuccessful" />,
            options: {
                variant: "success"
            }
        })
    );
    // update order info
    dispatch(actionUpdateOneOrder(orderId));
};
class HistoryPage extends Component {
    state = {
        loading: false,
        hasMore: false
    };

    pollingHistory = false;

    getPendingOrders = () =>
        this.props.orderHistory.filter(order => order.items.filter(item => item.status === "PROCESSING").length > 0);

    pollPendingOrders = async () => {
        this.pollingHistory = true;
        let pendingOrders = this.getPendingOrders();
        console.log(pendingOrders);
        while (pendingOrders.length > 0 && this.pollingHistory) {
            console.log(pendingOrders);
            await Promise.all(pendingOrders.map(order => this.props.dispatch(actionUpdateOneOrder(order.id))));
            await sleep(5000);
            pendingOrders = this.getPendingOrders();
        }
    };

    showRefundDialog = order => e => {
        const { dispatch } = this.props;
        dispatch({
            type: DIALOG.SHOW,
            payload: {
                body: <Text id="history.messages.refundRequestConfirm" fields={{ amount: order.refundable }} />,
                primaryButton: {
                    text: <Text id="history.actions.refund" />,
                    action: () => dispatch(actionRefundOneOrder(order.id))
                },
                secondaryButton: {
                    text: <Text id="dialog.labels.secondaryAction" />
                }
            }
        });
    };

    initData = async () => {
        await this.props.dispatch(actionFetchOrderHistory);
        this.pollPendingOrders();
    };

    componentDidMount() {
        this.initData();
    }

    render() {
        const { orderHistory } = this.props;
        return (
            <div className={styles.container}>
                <List
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            <Text id="history.labels.orderHistory" />
                        </ListSubheader>
                    }
                >
                    {orderHistory.map((order, idx) => {
                        const displayDate = new Date(order.timeCreated * 1000).toLocaleString();
                        return (
                            <li key={`order-${order.id}`}>
                                <ul className={styles.section}>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <span>
                                                    <Text id="history.labels.order" />
                                                    {`#${order.transaction}`}
                                                </span>
                                            }
                                            secondary={
                                                <span>
                                                    {displayDate}{" "}
                                                    <Text id={["history", "status", order.status.toLowerCase()]} />
                                                </span>
                                            }
                                        />
                                        {parseFloat(order.refundable) > 0 && (
                                            <ListItemSecondaryAction>
                                                <Button
                                                    onClick={this.showRefundDialog(order)}
                                                    className={styles.buttonRedeem}
                                                    color="secondary"
                                                >
                                                    <Text id={["history", "actions", "refund"]} />
                                                </Button>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                    {order.items.map(item => {
                                        const redeemable = item.status === "SUCCESS" && typeof item.href === "string";
                                        return (
                                            <ListItem key={`item-${item.id}`}>
                                                <ListItemText primary={`${item.title}-${item.description}`} />
                                                <ListItemSecondaryAction>
                                                    <Chip
                                                        className={styles.buttonRedeem}
                                                        label={
                                                            redeemable ? (
                                                                <Text id={["history", "status", "redeem"]} />
                                                            ) : (
                                                                <Text
                                                                    id={[
                                                                        "history",
                                                                        "status",
                                                                        item.status.toLowerCase()
                                                                    ]}
                                                                />
                                                            )
                                                        }
                                                        component="a"
                                                        href={redeemable && item.href}
                                                        color={redeemable ? "secondary" : "default"}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                    <Divider />
                    <ListItem>
                        <Typography variant="caption">
                            <Text id="history.messages.mostRecentOrdersOnly" fields={{ count: NUMBER_EACH_FETCH }} />
                        </Typography>
                    </ListItem>
                </List>
            </div>
        );
    }
}

const mapStateToProps = state => ({ orderHistory: state.orderHistory });

export default connect(mapStateToProps)(HistoryPage);
