import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProductCommonPage from "./templates/common";
import { ENUM_LOAD_PRODUCT_BY_ID } from "../../reducers";
import { getJson } from "../../utils/apiClient";
import { API_PRODUCT_BY_ID } from "../../configs/ApiUrls";
import { Text } from "../../locales";

const actionLoadProductByID = productID => async dispatch => {
    const apiProductById = `${API_PRODUCT_BY_ID}${productID}`;
    const resData = await getJson(apiProductById);
    console.log(resData);
    dispatch({
        type: ENUM_LOAD_PRODUCT_BY_ID.SUCCESS,
        payload: resData
    });
};

class ProductByIDPage extends React.Component {
    checkProducInfo = () => {
        if (!this.props.product) {
            const productID = this.props.match.params.id;
            this.props.dispatch(actionLoadProductByID(productID));
        }
    };

    componentDidMount() {
        this.checkProducInfo();
    }

    render() {
        const productID = this.props.match.params.id;

        const { productByID } = this.props;

        const product = productByID[productID];

        let RenderPage = ProductCommonPage;

        // const productType = (product || {}).type;
        // switch (productType) {
        //     case "redpacket":
        //         RenderPage = ProductRedpacketPage;
        //         break;
        //     default:
        //         RenderPage = ProductCommonPage;
        // }

        return product ? <RenderPage product={product} /> : <Text id="common.loading" />;
    }
}

const mapStateToProps = state => ({ productByID: state.productByID });

export default connect(mapStateToProps)(withRouter(ProductByIDPage));
