import { combineReducers } from "redux";
import { enqueueSnackbar } from "../actions/snackbar";

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const ENUM_LOADCOUPON = {
    STARTED: "LOADCOUPON/STARTED",
    SUCCESS: "LOADCOUPON/SUCCESS",
    ERROR: "LOADCOUPON/ERROR",
    RESETIFERROR: "LOADCOUPON/RESETIFERROR"
};

export const ENUM_LOAD_PRODUCT_CATEGORIES = {
    STARTED: "LOAD_PRODUCT_CATEGORIES/STARTED",
    SUCCESS: "LOAD_PRODUCT_CATEGORIES/SUCCESS",
    ERROR: "LOAD_PRODUCT_CATEGORIES/ERROR",
    RESETIFERROR: "LOAD_PRODUCT_CATEGORIES/RESETIFERROR"
};

export const ENUM_LOAD_PRODUCT_BY_CATEGORY = {
    STARTED: "LOAD_PRODUCT_BY_CATEGORY/STARTED",
    SUCCESS: "LOAD_PRODUCT_BY_CATEGORY/SUCCESS",
    ERROR: "LOAD_PRODUCT_BY_CATEGORY/ERROR",
    RESETIFERROR: "LOAD_PRODUCT_BY_CATEGORY/RESETIFERROR"
};

export const ENUM_SENDSTATS = {
    PENDING: "SENDSTATS/PENDING",
    SUCCESS: "SENDSTATS/SUCCESS",
    FAILURE: "SENDSTATS/FAILURE",
    READYSTATECHANGE: "SENDSTATS/READYSTATECHANGE"
};

export const ENUM_SHOWSIGNIN = {
    SHOW: "SHOWSIGNIN/SHOW",
    HIDE: "SHOWSIGNIN/HIDE"
};

export const ENUM_USERINFO_LOAD = {
    SUCCESS: "USERINFO_LOAD/SUCCESS",
    PENDING: "USERINFO_LOAD/PENDING",
    FAILURE: "USERINFO_LOAD/FAILURE"
};

export const ENUM_CART = {
    ADD: "CART/ADD",
    DELETE: "CART/DELETE",
    EDIT: "CART/EDIT",
    CLEAR: "CART/CLEAR"
};

export const ENUM_LOAD_PRODUCT_BY_ID = {
    SUCCESS: "LOAD_PRODUCT_BY_ID/SUCCESS",
    PENDING: "LOAD_PRODUCT_BY_ID/PENDING",
    FAILURE: "LOAD_PRODUCT_BY_ID/FAILURE"
};

export const ENUM_LOAD_ORDER_HISTORY = {
    UPDATE_ONE: "LOAD_ORDER_HISTORY/UPDATE_ONE", // refreshed one order
    LOAD_MORE: "LOAD_ORDER_HISTORY/LOAD_MORE" // load more orders
};

export const DIALOG = {
    SHOW: "DIALOG/SHOW",
    HIDE: "DIALOG/HIDE"
};

function loadCoupons(state = {}, action) {
    switch (action.type) {
        case ENUM_LOADCOUPON.STARTED:
            return { ...state, payload: null };
        case ENUM_LOADCOUPON.SUCCESS:
            return { ...state, payload: action.payload };
        case ENUM_LOADCOUPON.ERROR:
            return { ...state, payload: undefined, error: action.error };
        case ENUM_LOADCOUPON.RESETIFERROR:
            if (!state.error) {
                return state;
            }
            return { ...state, payload: undefined, error: undefined };
        default:
            return state;
    }
}

function loadProductCategories(state = {}, action) {
    switch (action.type) {
        case ENUM_LOAD_PRODUCT_CATEGORIES.STARTED:
            return { ...state, payload: null };
        case ENUM_LOAD_PRODUCT_CATEGORIES.SUCCESS:
            return { ...state, payload: action.payload };
        case ENUM_LOAD_PRODUCT_CATEGORIES.ERROR:
            return { ...state, payload: undefined, error: action.error };
        case ENUM_LOAD_PRODUCT_CATEGORIES.RESETIFERROR:
            if (!state.error) {
                return state;
            }
            return { ...state, payload: undefined, error: undefined };
        default:
            return state;
    }
}

function loadProductByCategory(state = {}, action) {
    switch (action.type) {
        case ENUM_LOAD_PRODUCT_BY_CATEGORY.STARTED:
            return { ...state, pending: true };
        case ENUM_LOAD_PRODUCT_BY_CATEGORY.SUCCESS:
            return { ...state, pending: false, payload: { ...state.payload, ...action.payload } };
        case ENUM_LOAD_PRODUCT_BY_CATEGORY.ERROR:
            return { ...state, pending: false, error: action.error };
        case ENUM_LOAD_PRODUCT_BY_CATEGORY.RESETIFERROR:
            if (!state.error) {
                return state;
            }
            return { ...state, pending: false, payload: undefined, error: undefined };
        default:
            return state;
    }
}

function sendStats(state = {}, action) {
    state = { ...state, uuid: state.uuid || uuidv4() };
    switch (action.type) {
        case ENUM_SENDSTATS.SUCCESS:
            return { ...state, sent: true };
        case ENUM_SENDSTATS.PENDING:
            return { ...state, sent: null };
        case ENUM_SENDSTATS.FAILURE:
            return { ...state, sent: undefined };
        case ENUM_SENDSTATS.READYSTATECHANGE:
            const new_payload = state.payload || {};
            new_payload[action.payload] = true;
            return { ...state, payload: new_payload };
        default:
            return state;
    }
}

function showSignIn(state = { visible: false }, action) {
    switch (action.type) {
        case ENUM_SHOWSIGNIN.SHOW:
            return { ...state, visible: true };
        case ENUM_SHOWSIGNIN.HIDE:
            return { ...state, visible: false };
        default:
            return state;
    }
}

function loadUserInfo(state = {}, action) {
    switch (action.type) {
        case ENUM_USERINFO_LOAD.SUCCESS:
            return { ...action.payload };
        case ENUM_USERINFO_LOAD.FAILURE:
        case ENUM_USERINFO_LOAD.PENDING:
            throw new Error("NOT IMPLEMENTED");
        default:
            return state;
    }
}

function reduceCart(state = [], action) {
    let retState;
    switch (action.type) {
        case ENUM_CART.ADD:
            retState = [...state, action.payload];
            break;
        case ENUM_CART.DELETE:
            retState = [...state].splice(action.payload.index);
            break;
        case ENUM_CART.CLEAR:
            retState = [];
            break;
        default:
            return state;
    }
    // update cart value in localstorage for future use.
    localStorage.setItem("cart", JSON.stringify(retState));
    return retState;
}

const loadProductsByID = (state = {}, action) => {
    switch (action.type) {
        case ENUM_LOAD_PRODUCT_BY_ID.SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case ENUM_LOAD_PRODUCT_BY_ID.PENDING:
        case ENUM_LOAD_PRODUCT_BY_ID.FAILURE:
            throw new Error("NOT IMPLEMENTED");
        default:
            return state;
    }
};

const hydrateCartState = () => {
    try {
        const cartLS = JSON.parse(localStorage.getItem("cart"));
        if (Array.isArray(cartLS)) return cartLS;
        else throw new TypeError("localStorage.cart is not an array.");
    } catch (err) {
        console.warn("Loading cart failed:", err);
        console.warn("Initializing empty cart with []");
        return [];
    }
};

const orderHistoryReducer = (state = [], action) => {
    switch (action.type) {
        case ENUM_LOAD_ORDER_HISTORY.LOAD_MORE:
            return [...state, ...action.payload.orders];
        case ENUM_LOAD_ORDER_HISTORY.UPDATE_ONE:
            return state.map(order => (order.id === action.payload.order.id ? action.payload.order : order));
        default:
            return state;
    }
};

export const errorReducer = (err, lastAction) => (dispatch, getState) => {
    console.error("From errorReducer:", err, lastAction);
    dispatch(
        enqueueSnackbar({
            message: err.message,
            options: {
                variant: "warning"
            }
        })
    );
};

export const preloadState = {
    cart: hydrateCartState()
};

const notificationsReducer = (state = [], action) => {
    switch (action.type) {
        case "ENQUEUE_SNACKBAR":
            return [...state, action.notification];

        case "REMOVE_SNACKBAR":
            return state.filter(notification => notification.key !== action.key);
        default:
            return state;
    }
};

const dialogReducer = (state = { open: false }, action) => {
    switch (action.type) {
        case DIALOG.SHOW:
            return {
                open: true,
                title: action.payload.title,
                body: action.payload.body,
                primaryButton: action.payload.primaryButton,
                secondaryButton: action.payload.secondaryButton
            };
        case DIALOG.HIDE:
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    coupons: loadCoupons,
    stats: sendStats,
    user: loadUserInfo,
    productCategories: loadProductCategories,
    productByCategory: loadProductByCategory,
    productByID: loadProductsByID,
    signIn: showSignIn,
    cart: reduceCart,
    orderHistory: orderHistoryReducer,
    notifications: notificationsReducer,
    dialog: dialogReducer
});

export default rootReducer;
