import zh from "./zh";
import en from "./en";
import locale2 from "locale2";
import { get } from "lodash-es";
import template from "preact-i18n/src/lib/template";

const locales = {
    zh,
    en
};

export function getAppLocale() {
    // TODO: make it load only once.
    let browserLocale = locale2; // in the form of en-SG
    let locale = browserLocale || "en"; // fallback to en
    // overwrite zh and en to SG locale here
    if (locale.startsWith("zh")) locale = "zh-SG";
    else if (locale.startsWith("en")) locale = "en-SG";
    return locale;
}

/**
 * @param {string} localepath path to locale files directory
 * @param {string} fallback fallback language when the locale file is not found
 */
export function loadLocDef(fallback = "en", forcelocale) {
    let locale = getAppLocale();
    let localeShort = locale.substr(0, 2);
    let localeDict = locales[forcelocale] || locales[localeShort] || locales[fallback];
    return localeDict;
}

// TODO: load this dict in a more integrated way
const localeDict = loadLocDef();
/**
 *
 * @param {string} id
 * @param {string} fallback
 * @returns {string}
 */
export function t(id, fields, fallback) {
    return template(get(localeDict, id, fallback), fields);
}

export { IntlProvider, Text } from "./i18n";
