import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "url-polyfill";
import "typeface-roboto";
import "./assets/material-iconfont/material-icons.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import rootReducer, { preloadState, errorReducer } from "./reducers";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { IntlProvider, loadLocDef } from "./locales";
import ReactGA from "react-ga";

import reduxAsyncCatch from "redux-async-catch";

import { composeWithDevTools } from "redux-devtools-extension";

// const reduxAsyncCatch = handler => store => next => async action => {
//     try {
//         return await next(action);
//     } catch (e) {
//         store.dispatch(handler(e, action));

//         return e;
//     }
// };

const history = createBrowserHistory();

const debug = process.env.NODE_ENV !== "production";

if (debug) {
    console.log("In debug mode.");
} else {
    console.log("In producion mode");
    const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
    if (!GA_TRACKING_ID) {
        console.warn("Google Analytics ID not found, skipping analytics.");
    } else {
        ReactGA.initialize(GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}

const intlDefs = loadLocDef("en");

const store = debug
    ? createStore(
          connectRouter(history)(rootReducer), // new root reducer with router state
          preloadState,
          composeWithDevTools(
              applyMiddleware(
                  routerMiddleware(history), // for dispatching history actions
                  reduxAsyncCatch(errorReducer),
                  thunk
              )
          )
      )
    : createStore(
          connectRouter(history)(rootReducer), // new root reducer with router state
          preloadState,
          applyMiddleware(
              routerMiddleware(history), // for dispatching history actions
              reduxAsyncCatch(errorReducer),
              thunk
          )
      );

ReactDOM.render(
    <IntlProvider definition={intlDefs}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </IntlProvider>,
    document.getElementById("root")
);

window.globalStorage = {
    utmSource: new URLSearchParams(window.location.search).get("utm_source") || undefined
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
