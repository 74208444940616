import React from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import ProductListFragment from "../ProductListFragment";
import styles from "./style.module.scss";
import { Text } from "../../locales";

export default class ProductsFragment extends React.Component {
    state = {
        tabSelected: 0
    };

    handleTabChange = (event, value) => {
        this.setState({
            tabSelected: value
        });
    };

    render() {
        const { productCategories } = this.props;
        const { tabSelected } = this.state;
        const category = productCategories[tabSelected];
        const loading = !productCategories || productCategories.length === 0;
        const navTabHeader = loading ? (
            <div>
                <LinearProgress />
                <center>
                    <Typography variant="caption">
                        <Text id="products.loading" />
                    </Typography>
                </center>
            </div>
        ) : (
            <Paper elevation={2} component="nav" className={styles.navTabHeader}>
                <Tabs
                    value={tabSelected}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="on"
                    classes={{
                        root: styles.tabs,
                        scrollButtons: styles.scrollButton
                    }}
                >
                    {productCategories.map(elem => (
                        <Tab label={elem.category} key={elem.id} />
                    ))}
                </Tabs>
            </Paper>
        );
        return (
            <div>
                {navTabHeader}
                {loading ? <span /> : <ProductListFragment key={category.id} category={category} />}
            </div>
        );
    }
}
