import React, { Component } from "react";
import { connect } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HelpIcon from "@material-ui/icons/Help";
import UserInfoFragment from "../../components/UserInfoFragment";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { Text } from "../../locales";
import { AUTHAPI_LOGOUT } from "../../configs/ApiUrls";
import { deleteJson } from "../../utils/apiClient";
import { showAlert } from "../../actions/dialog";

const actionSignOut = async () => {
    const res = await deleteJson(AUTHAPI_LOGOUT, {
        method: "DELETE"
    });
    console.log(res);
    window.location.reload();
};

class MePage extends Component {
    state = {
        loading: false
    };

    render() {
        return (
            <div>
                <UserInfoFragment user={this.props.user} />
                <div style={{ backgroundColor: "white" }}>
                    <List>
                        <Divider />
                        {/* <ListItem button>
                            <ListItemIcon>
                                <MoreHorizIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Text id="me.settings.changePassword" />} secondary=" " />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemIcon>
                                <NewReleasesIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Text id="me.feedback.missingProduct" />} secondary=" " />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <FeedbackIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Text id="me.feedback.suggestions" />} secondary=" " />
                        </ListItem> */}
                        <ListItem
                            button
                            onClick={() =>
                                this.props.dispatch(
                                    showAlert(<Text id="me.feedback.getHelp" />, <Text id="me.feedback.getHelpText" />)
                                )
                            }
                        >
                            <ListItemIcon>
                                <HelpIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Text id="me.feedback.getHelp" />} secondary=" " />
                        </ListItem>
                        <Divider />
                        {this.props.user.userIdentifier && (
                            <>
                                <ListItem button onClick={() => this.props.dispatch(actionSignOut)}>
                                    <ListItemIcon>
                                        <ExitToApp color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Text id="me.exit.primary" />}
                                        secondary={<Text id="me.exit.secondary" />}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                        )}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(MePage);
