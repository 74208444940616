import React, { Component } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import { ENUM_LOAD_PRODUCT_CATEGORIES } from "../../reducers";

import { API_PRODUCT_CATEGORIES } from "../../configs/ApiUrls";
import PathReplaced from "../../utils/PathReplaced";
import ProductsFragment from "../../components/ProductsFragment";
import { getAppLocale } from "../../locales";

import ProductByIdPage from "./byid";
import styles from "./style.module.scss";

import bannerEn from "../../assets/banner_en.jpg";
import bannerZh from "../../assets/banner_zh.jpg";

function getProductCategories() {
    return async (dispatch, getState) => {
        const currState = getState();
        try {
            if (currState.productCategories.payload) {
                return;
            }
            dispatch({
                type: ENUM_LOAD_PRODUCT_CATEGORIES.STARTED,
                payload: null,
                error: null
            });
            let res = await fetch(API_PRODUCT_CATEGORIES);
            let { resultCode, errorCode, data } = await res.json();
            if (resultCode !== 0) {
                throw new Error(`Error retrieving data from server (invalid resultCode).`);
            }
            if (errorCode !== 0) {
                throw new Error(`Error retrieving data from server (invalid errorCode).`);
            }
            dispatch({
                type: ENUM_LOAD_PRODUCT_CATEGORIES.SUCCESS,
                payload: data,
                error: null
            });
        } catch (e) {
            console.error(e);
            dispatch({
                type: ENUM_LOAD_PRODUCT_CATEGORIES.ERROR,
                payload: null,
                error: e
            });
        }
    };
}

class ProductPage extends Component {
    state = {
        loading: false
    };

    checkTncClicked = () => {
        let { dispatch } = this.props;
        dispatch(push(PathReplaced(window.location.href, "/tnc")));
    };

    setStateAsync(state) {
        return new Promise(resolve => this.setState(state, resolve));
    }

    loadProductCategories = () => {
        this.props.dispatch(getProductCategories());
    };

    componentDidMount() {
        this.loadProductCategories();
    }

    render() {
        const { productCategories, match } = this.props;
        const productCategoriesPayload = productCategories.payload || [];
        let bannerImage;
        switch (getAppLocale()) {
            case "en-SG":
                bannerImage = bannerEn;
                break;
            case "zh-SG":
                bannerImage = bannerZh;
                break;
            default:
                bannerImage = bannerEn;
        }
        const productListPage = (
            <div>
                <div>
                    <div className={styles.banner}>
                        <img src={bannerImage} alt="Banner" />
                    </div>
                </div>
                <ProductsFragment
                    products={this.props.productByCategory}
                    productCategories={productCategoriesPayload}
                    reloadConfigurations={this.reloadConfigurations}
                />
            </div>
        );
        return (
            <Switch>
                <Route path={match.path} exact render={() => productListPage} />
                <Route path={`${match.path}/:id`} component={ProductByIdPage} />
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    stats: state.stats,
    productCategories: state.productCategories,
    productByCategory: state.productByCategory
});

export default connect(mapStateToProps)(withRouter(ProductPage));
